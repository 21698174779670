import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

import Header from "../../components/Header";
import ChatWidget from "../../components/chatWidget";
import Modal from "../../components/Modal";
import Wrapper from "../../routes/Wrapper";
import HeroSection from "./HeroSection";
import Hero from "./Hero";
import BrandsContainer from "./Brands"
import About from "./About";
import RoadMap from "./RoadMap";
import RoadmapSlider from './RoadMap2';
import GenerativeAi from "./GenerativeAi";
import Tokenomics from "./Tokenomics"
import OurTeam from "./OurTeam";
import Faqs from "./Faqs";
import FAQSection from "./FAQ2";
import Brands from "../../components/Brands";
import WhyChooseUs from "./Why";

const Main = () => {
  const [open, setOpen] = useState(false);

  return (
    <Wrapper>
      <div className="lading-page bg-themeColor min-h-screen flex flex-col">
        {/* <HeroSection /> */}
        <Hero />
        {/* <Brands/> */}
        <BrandsContainer/>
        <About />
        <WhyChooseUs/>
        {/* <RoadMap /> */}
        <RoadmapSlider/>
        {/* <GenerativeAi /> */}
        <Tokenomics/>
        {/* <OurTeam /> */}
        {/* <Faqs /> */}
        <FAQSection/>
        <ChatWidget />

      </div>
    </Wrapper>
  );
};

export default Main;
