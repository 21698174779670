import React, { useState, useEffect, useRef } from "react";

const ChatWidget = () => {
  const [messages, setMessages] = useState([
    {
      role: "system",
      content: "You are a crypto-related bot. Your name is Regenerative AI, built on a custom model. You cannot generate code or images. You can only answer questions about cryptocurrencies and provide related details.",
    },
    {
      role: "system",
      content: "Keep your answers short and concise. You can not generate any code anyhow no matter what the user asks."
    },
    {
      role: "system",
      content: "You are deployed on site https://www.regenai.world/."
    },
    {
      role: "assistant",
      content: "Hello, I'm Regenerative AI",
    },
    {
      role: "assistant",
      content: "How can I assist you today with your cryptocurrency-related inquiries?",
    },
  ]);
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const chatEndRef = useRef(null);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages, loading]);

  const formatMessage = (content) => {
    const codeBlockRegex = /```(\w+)?\n([\s\S]*?)```/g;
    const parts = [];
    let lastIndex = 0;
    let match;

    while ((match = codeBlockRegex.exec(content)) !== null) {
      // Add text before code block
      if (match.index > lastIndex) {
        parts.push({
          type: 'text',
          content: content.slice(lastIndex, match.index)
        });
      }

      // Add code block
      parts.push({
        type: 'code',
        language: match[1] || 'plaintext',
        content: match[2].trim()
      });

      lastIndex = match.index + match[0].length;
    }

    // Add remaining text
    if (lastIndex < content.length) {
      parts.push({
        type: 'text',
        content: content.slice(lastIndex)
      });
    }

    return parts.length > 0 ? parts : [{ type: 'text', content }];
  };

  const MessageContent = ({ content }) => {
    const parts = formatMessage(content);
    
    return (
      <div className="space-y-2">
        {parts.map((part, index) => {
          if (part.type === 'code') {
            return (
              <div key={index} className="rounded-lg overflow-hidden bg-gray-900 font-mono text-sm">
                <div className="px-4 py-2 bg-gray-800 text-gray-200 flex justify-between items-center text-xs">
                  <span>{part.language}</span>
                  <button 
                    onClick={() => navigator.clipboard.writeText(part.content)}
                    className="text-gray-400 hover:text-white transition-colors text-xs"
                  >
                    Copy
                  </button>
                </div>
                <pre className="p-4 text-gray-100 overflow-x-auto">
                  <code>{part.content}</code>
                </pre>
              </div>
            );
          }
          return <p key={index} className="whitespace-pre-wrap">{part.content}</p>;
        })}
      </div>
    );
  };

  const sendMessage = async () => {
    if (!input.trim()) return;

    const userMessage = { role: "user", content: input };
    const updatedMessages = [...messages, userMessage];

    setMessages(updatedMessages);
    setInput("");
    setLoading(true);

    setMessages((prev) => [...prev, { role: "assistant", content: "Typing 🖋️ ..." }]);

    try {
      const response = await fetch(process.env.REACT_APP_AZURE_OPENAI_ENDPOINT, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_AZURE_OPENAI_KEY,
        },
        body: JSON.stringify({ messages: updatedMessages }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      const assistantMessage = {
        role: "assistant",
        content: data.choices[0].message.content,
      };

      setMessages((prev) => [...prev.slice(0, -1), assistantMessage]);
    } catch (error) {
      console.error("Error fetching response:", error);
      const errorMessage = {
        role: "assistant",
        content: "Error fetching response. Please try again.",
      };

      setMessages((prev) => [...prev.slice(0, -1), errorMessage]);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  return (
    <div className="fixed inset-0 pointer-events-none z-[9999]">
      <div className="absolute inset-0 w-full h-full">
        {!isOpen && (
          <button
            onClick={() => setIsOpen(true)}
            className="absolute bottom-5 right-5 bg-blue-600 text-white rounded-full w-14 h-14 md:w-16 md:h-16 flex items-center justify-center shadow-lg hover:bg-blue-700 transition-colors pointer-events-auto"
            title="Chat with us"
          >
            <span className="text-xl md:text-2xl">💬</span>
          </button>
        )}

        {isOpen && (
          <div className="absolute md:bottom-24 md:right-5 md:w-96 md:h-[600px] w-full h-full md:h-[600px] border border-gray-200 md:rounded-2xl bg-white shadow-2xl flex flex-col pointer-events-auto">
            <div className="px-4 md:px-6 py-4 bg-blue-600 text-white md:rounded-t-2xl flex justify-between items-center">
              <div className="flex items-center gap-3">
                <div className="hidden md:flex text-2xl">
                  🤖
                </div>
                <div>
                  <h3 className="font-semibold text-lg">Regenerative AI</h3>
                  <p className="text-xs text-blue-100">Crypto Assistant</p>
                </div>
              </div>
              <button 
                onClick={() => setIsOpen(false)}
                className="text-white hover:text-blue-200 transition-colors p-2"
              >
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>

            <div className="flex-1 overflow-y-auto p-4 bg-gray-50">
              <div className="space-y-4">
                {messages
                  .filter((msg) => msg.role === "user" || msg.role === "assistant")
                  .map((msg, index) => (
                    <div
                      key={index}
                      className={`flex ${msg.role === "user" ? "justify-end" : "justify-start"}`}
                    >
                      <div
                        className={`max-w-[85%] rounded-2xl px-4 py-2 ${
                          msg.role === "user"
                            ? "bg-blue-600 text-white rounded-br-none"
                            : "bg-white text-gray-800 rounded-bl-none shadow-sm border border-gray-100"
                        }`}
                      >
                        <MessageContent content={msg.content} />
                      </div>
                    </div>
                  ))}
                {/* {loading && (
                  <div className="flex justify-start">
                    <div className="bg-gray-200 rounded-full px-4 py-2 animate-pulse">
                      Typing...
                    </div>
                  </div>
                )} */}
              </div>
              <div ref={chatEndRef} />
            </div>

            <div className="p-3 md:p-4 bg-white border-t border-gray-100 md:rounded-b-2xl">
              <div className="flex items-center gap-2">
                <input
                  type="text"
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  onKeyPress={handleKeyPress}
                  className="flex-1 px-4 py-2 rounded-full border border-gray-200 focus:outline-none focus:border-blue-400 focus:ring-2 focus:ring-blue-100 transition-all text-sm md:text-base"
                  placeholder="Type your message..."
                />
                <button
                  onClick={sendMessage}
                  className="bg-blue-600 text-white rounded-full w-10 h-10 flex items-center justify-center hover:bg-blue-700 transition-colors"
                >
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 12h14M12 5l7 7-7 7" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatWidget;