import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { CloseIcon } from "../../assets/Icons";

import { useWeb3Modal } from '@web3modal/wagmi/react';
import { useAccount } from "wagmi";

const Sidebar = ({ openSidebar, setOpenSidebar }) => {
  console.log("openSidebar....", openSidebar);
  
  const navBarItems = [
    { lbl: "Home", slug: "/" },
    { lbl: "Road Map", slug: "#roadmap" },
    { lbl: "Staking", slug: "/staking" },
    { lbl: "Staking Pass", slug: "/staking-pass" },
    { lbl: "About us", slug: "#aboutus" },
    { lbl: "Tokenomics", slug: "/#tokenomics" },
  ];

  const { open, close } = useWeb3Modal();
  const { isConnected, address } = useAccount();

  useEffect(() => {
    document.body.addEventListener("click", () => {
      document.body.style.overflowY = "auto";
      setOpenSidebar(false);
    });

    return () => {
      // Cleanup event listener on component unmount
      document.body.removeEventListener("click", () => {
        document.body.style.overflowY = "auto";
        setOpenSidebar(false);
      });
    };
  }, [setOpenSidebar]);

  return (
    <>
      {/* Backdrop for the blur effect */}
      <div
        className={`sidebar-backdrop ${openSidebar ? "show" : ""}`}
        onClick={() => setOpenSidebar(false)}
      />

      <div className={`sidebar-s fixed rel anim ${openSidebar ? "show" : "hide"}`}>
        <div
          className={`side-block flex col anim ${openSidebar ? "show" : "hide"}`}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="hdr flex items-center justify-between gap-2">
            <div className="hdr-tag">
              {/* <img src="./images/logo_2.webp" className="h-14" /> */}
              <img src="./images/logo_3.jpeg" className="h-14 logo" alt="Logo" />
            </div>
            <div
              className="icon-close flex aic jc"
              onClick={() => setOpenSidebar(false)}
            >
              <CloseIcon />
            </div>
          </div>

          <div>
            <div className="items flex aic flex-col">
              {navBarItems.map((item, index) => (
                item.slug.startsWith("#") ? (
                  <a
                    key={index}
                    href={item.slug}
                    className={`list-item flex`}
                    onClick={() => setOpenSidebar(false)} // Close sidebar on click
                  >
                    <div className="li">{item.lbl}</div>
                  </a>
                ) : (
                  <Link
                    key={index}
                    to={item.slug}
                    className={`list-item flex`}
                    onClick={() => setOpenSidebar(false)} // Close sidebar on click
                  >
                    <div className="li">{item.lbl}</div>
                  </Link>
                )
              ))}

              <button onClick={open} className="btn-stack button">
                {!isConnected
                  ? "Connect Wallet"
                  : `${address.slice(0, 6)}...${address.slice(38, 42)}`}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
